@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
    body {
      @apply bg-secondary font-sans;
    }
    .page {
      @apply w-full h-screen overflow-hidden;
    }
    .h1 {
      @apply xl:text-[45px] leading-tight lg:text-[40px] md:text-[35px] sm:text-[35px] xs:text-[28px] font-semibold;
    }
    .h2 {
      @apply xl:text-[30px] leading-tight md:text-[24px] sm:text-[16px] xs:text-[14px] font-semibold;
    }
    p {
      @apply xl:text-[16px] md:text-[14px] sm:text-[12px] xs:text-[11px] text-black/60 font-medium;
    }
    .btn {
      @apply h-[52px] text-gray-600;
    }
    .input {
      @apply xl:h-[48px] lg:h-[48px] md:h-[48px] sm:h-[32px] xs:h-[32px] rounded-lg capitalize;
    }
    .input,
    .textarea, 
    .btn {
      @apply mt-1 px-3 py-2 focus:outline-none border focus:border-accent focus:ring-accent block focus:ring-1 focus:ring-opacity-50 rounded-md  focus:shadow-lg sm:text-sm xs:text-xs border-gray-300 w-full shadow-md ;
    }
    .textarea {
      @apply  h-[180px] capitalize rounded-lg resize-none;
    }
    .checkbox {
      @apply h-3 w-3 shadow-xl shadow-slate-900;
    }
  }
  